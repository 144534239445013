export default {

	// api请求地址
	baseUrl: 'https://t.27py.cn',

	// 图片域名
	imgDomain: 'https://t.27py.cn',

	// 腾讯地图key
	 mpKey: '',

	// 客服
	 webSocket: '',

	// api安全
	apiSecurity: true,

	//本地端主动给服务器ping的时间, 0 则不开启 , 单位秒
	pingInterval: 1500,

	// 公钥
	publicKey: `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAn7jswOzNvLpen2c0jhkF
ct+skDonPawmgManYY+CZuK9/hZkK65Ja3H8ExrzBS00n/erbCNyAsh/ltM/N6Xj
u53jn7mCgESKU13QPtAk6HwQN83zDJMz7Pu1PL8oxN0XpIdAHQn3BAe/mp4GMMcY
EJ89+NxREKuACWmVC76q75CBsRRUDrSLo446S33yRZ0bgGYL/0YxarLGIdc/IF+Y
44z+loVLH1j1+JYQKGJlRFcHk+M3jZNF0hLpCZhanFYXAHtzBTQly6dW+anqTcrT
G7diZRo/Zdy5Zmj3PGS3aMQ6UtMjvOyBNZNPL3aASW7jCMJ1XE43xSXcquqrMcNB
HG6qCYtSIanZ32M/IJTgd3Yldi7rdtPAs4L0pWghRYEX8ttZ+u3L/x8K/K/6wLu6
2+D6OjX+KejVOCMMZNEqsNJGIO5nO44jmxPfsxLiudJbV+wUi6zN4pV6FWAJcA9v
cWch9k0ipcR3Hga70RE7ElB5P82lJtHvRObV3x3ERIx62BMel5l/osrETBtj+8W3
0n/T46VN8y8q57/leN9sKztEiX7DYarfAsv2Ay2Sw1tLEH9UJS2ArmOJs4GUIYoL
ARuWN199is9397pEF+tj8nsradN/GNjVhXl8G+3TIU3rytCVgi+IllVxAWDVn2wd
H4Zgs6gSBikvdTWA5TLGDt0CAwEAAQ==
-----END PUBLIC KEY-----`,

}
